import { Page } from "../layout/page";
import { Accordion, Title } from "@mantine/core";
import React, { useState, useEffect } from "react";
import ReportDebitNote from "../components/reportDebitNote";
import { useTranslation } from "react-i18next";

import ReportRenewal from "../components/reportRenewal";

const GIReport = ({}) => {
  const { t } = useTranslation();

  const reports = [
    {
      title: t("Debit Note Report"),
      reportComponent: ReportDebitNote,
    },
    {
      title: t("GI Renewal Report"),
      reportComponent: ReportRenewal,
    },
  ];

  return (
    <Page title={t("Report")}>
      <Accordion
        variant="separated"
        radius="xs"
        defaultValue="customization"
        mt={"xl"}
      >
        {reports.map((r) => (
          <Accordion.Item value={r.title}>
            <Accordion.Control>
              <Title order={5}>{t(r.title)}</Title>
            </Accordion.Control>
            <Accordion.Panel>
              <r.reportComponent />
            </Accordion.Panel>
          </Accordion.Item>
        ))}
      </Accordion>
    </Page>
  );
};

export default GIReport;
