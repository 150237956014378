import { useEffect } from "react";
import { useServerApi } from "../hooks/userServerApi";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { FormSectionCard } from "../layout/page";
import { useForceUpdate } from "@mantine/hooks";
import _ from "lodash";
import {
  Button,
  Group,
  Badge,
  Checkbox,
  Card,
  Text,
  Paper,
  Divider,
  ThemeIcon,
  Image,
  Tooltip,
} from "@mantine/core";
import { useCellRender } from "../hooks/useCellRender";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Grid } from "@mantine/core";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Menu, ActionIcon } from "@mantine/core";
import {
  IconDotsVertical,
  IconRefresh,
  IconCheck,
  IconAlertTriangle,
} from "@tabler/icons";
import { showNotification } from "@mantine/notifications";
import { ImageThumbList } from "./clientPaymentList";
import ReactJson from "react-json-view";
import { ScrollArea } from "@mantine/core";
import { getFileIcon, getFileIconColor, isImageFile } from "./fileList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";

const ReceiptList = ({ files, size = 20, max = 2 }) => {
  const imageFiles = files?.filter((file) => isImageFile(file.mimetype));
  const otherFiles = files?.filter((file) => !isImageFile(file.mimetype));
  return (
    <div style={{ display: "flex", flexWrap: "wrap", gap: "5px" }}>
      {imageFiles?.slice(0, max)?.map((file, index) => (
        <Image
          src={file.url}
          alt={`Thumbnail ${index + 1}`}
          width={size}
          height={size}
          fit="cover"
          onClick={() => {
            window.open(file.url, "_blank");
          }}
        />
      ))}
      {otherFiles?.slice(0, max)?.map((file, index) => (
        <FontAwesomeIcon
          key={index}
          icon={faFilePdf}
          alt={file.name}
          title={file.name}
          onClick={() => {
            window.open(file.url, "_blank");
          }}
          color={getFileIconColor(file.mimetype)}
          size="xs"
        />
      ))}
    </div>
  );
};

const MatchCard = ({ matching, onUndo, bankAccount, onConfirm }) => {
  const [cellRender] = useCellRender();
  const [api] = useServerApi();
  const forceUpdate = useForceUpdate();

  const confirmMatching = async () => {
    try {
      // console.log("confirmMatching", matching);
      if (!bankAccount?._id) return;
      const result = await api.BankAccount.confirmMatching(
        matching,
        bankAccount._id
      );
      // console.log("result", result);
      if (onConfirm) onConfirm(matching.bankTransaction._id);
      showNotification({
        title: "Success",
        message: "Confirm matching successfully",
        color: "green",
      });
    } catch (error) {
      // console.log(error);
      showNotification({
        title: "Error",
        message: error,
        color: "red",
      });
    }
  };

  const undoMatching = () => {
    try {
      matching.payment.matched = false;
      matching.payment = null;

      matching.totalReceivedAmount = matching.bankTransaction.amount;
      matching.totalPaymentAmount = 0;
      matching.totalDifference = matching.totalReceivedAmount;
      if (onUndo) onUndo();
      forceUpdate();
    } catch (error) {
      console.log(error);
    }
  };

  const { t } = useTranslation();
  const [forceConfirm, setForceConfirm] = useState(false);
  const transaction = matching.bankTransaction;
  const payment = matching.payment;
  const firstDN = !_.isEmpty(payment?.debitNoteAssignments)
    ? payment?.debitNoteAssignments[0]?.debitNote
    : null;
  return (
    <>
      <Droppable droppableId={matching.id}>
        {(provided, snapshot) => (
          <Card my="md" {...provided.droppableProps} ref={provided.innerRef}>
            <Grid gutter="xs" columns={24}>
              <Grid.Col span={18}>
                {transaction && (
                  <Group
                    mb="md"
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <Badge radius="sm">{transaction.code} </Badge>
                    <Text size="xs">
                      {moment(transaction.date).format("YYYY-MM-DD")}
                    </Text>
                    <Text size="xs" weight="bold">
                      {cellRender.Currency(transaction.amount)}
                    </Text>
                    <Text size="xs" italic lineClamp={1}>
                      {transaction.detail}
                    </Text>
                  </Group>
                )}

                {/* {matching.payment && <Divider mb="md" variant="dashed" />} */}

                {payment && (
                  <Draggable
                    key={payment._id}
                    draggableId={payment._id}
                    index={0}
                  >
                    {(provided, snapshot) => (
                      <>
                        <Group
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <Badge radius="sm" color="orange" mr={15}>
                            {payment.code}
                          </Badge>
                          <Text size="xs">
                            {moment(payment.date).format("YYYY-MM-DD")}
                          </Text>
                          <Text size="xs" weight="bold">
                            {cellRender.Currency(payment.amount)}
                          </Text>
                          <Text
                            size="xs"
                            italic
                            lineClamp={1}
                            mt={1}
                            lineClamp={1}
                          >
                            {firstDN?.client?.name}
                          </Text>
                        </Group>
                      </>
                    )}
                  </Draggable>
                )}

                {payment && (
                  <>
                    <Group mt="xl" spacing={5}>
                      {matching.totalDifference === 0 ? (
                        <ThemeIcon color="green" size="xs" radius="xl">
                          <IconCheck size="1rem" />
                        </ThemeIcon>
                      ) : (
                        <ThemeIcon color="red" size="xs" radius="xl">
                          <IconAlertTriangle size="1rem" />
                        </ThemeIcon>
                      )}

                      <Text size="xs" weight="bold" mr="xl">
                        Amount
                      </Text>

                      <ThemeIcon
                        color={
                          moment(matching.payment.date).isSame(
                            matching.bankTransaction.date,
                            "day"
                          )
                            ? "green"
                            : "red"
                        }
                        size="xs"
                        radius="xl"
                      >
                        {moment(matching.payment.date).isSame(
                          matching.bankTransaction.date,
                          "day"
                        ) ? (
                          <IconCheck size="1rem" />
                        ) : (
                          <IconAlertTriangle size="1rem" />
                        )}
                      </ThemeIcon>
                      <Text size="xs" weight="bold" mr="xl">
                        Date
                      </Text>

                      {matching.totalDifference !== 0 && (
                        <Group position="apart" noWrap ml="xl">
                          <Badge color="red" radius="sm" size="xs">
                            {t("Difference")}
                          </Badge>
                          <Text size="xs" align="right">
                            {cellRender.Currency(matching.totalDifference)}
                          </Text>
                        </Group>
                      )}

                      <ReceiptList files={payment.files} />
                    </Group>
                  </>
                )}
              </Grid.Col>
              <Grid.Col span={5}>
                {matching.payment && (
                  <Group mb="xs" position="right">
                    <Button
                      size="xs"
                      fullWidth
                      onClick={() => confirmMatching()}
                      disabled={
                        !forceConfirm &&
                        matching.totalReceivedAmount !==
                          matching.totalPaymentAmount
                      }
                    >
                      Confirm
                    </Button>
                  </Group>
                )}

                {matching.totalDifference !== 0 && matching.payment && (
                  <Checkbox
                    mt="xs"
                    size="xs"
                    label="Force Confirm"
                    checked={forceConfirm}
                    defaultChecked={false}
                    onChange={(event) =>
                      setForceConfirm(event.currentTarget.checked)
                    }
                  />
                )}
              </Grid.Col>

              <Grid.Col span={1}>
                {matching.payment && (
                  <Menu shadow="md">
                    <Menu.Target>
                      <ActionIcon size="xs">
                        <IconDotsVertical size="1rem" />
                      </ActionIcon>
                    </Menu.Target>

                    <Menu.Dropdown size="xs">
                      <Menu.Item
                        icon={<IconRefresh size={12} />}
                        onClick={() => undoMatching()}
                      >
                        Undo
                      </Menu.Item>
                    </Menu.Dropdown>
                  </Menu>
                )}
              </Grid.Col>
            </Grid>
          </Card>
        )}
      </Droppable>
    </>
  );
};

const PaymentCard = ({ payment, index, droppableProps, innerRef }) => {
  const [cellRender] = useCellRender();

  const firstDN = !_.isEmpty(payment?.debitNoteAssignments)
    ? payment.debitNoteAssignments[0]?.debitNote
    : null;

  return (
    <Card my="md" style={{ overflow: "visible" }}>
      <Draggable key={payment._id} draggableId={payment._id} index={index}>
        {(provided, snapshot) => (
          <>
            {/* <ReactJson src={payment} style={{ background: "white" }} /> */}
            <Group
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              <Badge radius="sm" color="orange">
                {payment.code}
              </Badge>
              <Text size="xs" fw={700}>
                {moment(payment.date).format("YYYY-MM-DD")}
              </Text>
              <Text size="xs" fw={700}>
                {cellRender.Currency(payment.amount)}
              </Text>

              <ReceiptList files={payment.files} />
            </Group>

            <Group>
              <Text size="xs" color="dimmed" lineClamp={1} mt="xs">
                {firstDN?.policyNumber ?? firstDN?.code}
              </Text>
              <Text size="xs" color="dimmed" lineClamp={1} mt="xs">
                {firstDN?.client?.name}
              </Text>
            </Group>
          </>
        )}
      </Draggable>
    </Card>
  );
};

const BankTransactionMatching = ({ bankAccount, type = "PAYMENT" }) => {
  const [api] = useServerApi();
  const [matchings, setMatchings] = useState([]);
  const [payments, setPayments] = useState([]);
  const [debitNotes, setDebitNotes] = useState([]);

  const { t } = useTranslation();
  const forceUpdate = useForceUpdate();

  const getMatchingTransaction = async () => {
    try {
      if (!bankAccount?._id) return;
      const result = await api.BankAccount.getMatchingTransaction(
        bankAccount?._id,
        type === "REFUND" ? "WITHDRAWAL" : "DEPOSIT"
      );
      // console.log(result);
      if (!result?.bankTransactions) return;
      const matchings = [];
      for (const [index, transaction] of result.bankTransactions.entries()) {
        matchings.push({
          id: `matching-${index}`,
          bankTransaction: transaction,
          payment: null,
          amount: transaction.amount,
          description: transaction.description,
          date: transaction.date,
          totalReceivedAmount: transaction.amount,
          totalPaymentAmount: 0,
          totalDifference: transaction.amount,
        });
      }
      setMatchings(matchings);
    } catch (error) {
      console.log(error);
    }
  };

  const getMatchingPayment = async () => {
    try {
      const result = await api.search({
        apiEntity: "clientPayment",
        pageSize: 1000,
        currentPage: 1,
        searchQuery: {
          bankAccount: bankAccount?._id,
          status: "MATCHING",
          type: type === "REFUND" ? "REFUND" : ["PAYMENT", null],
        },
        sort: { by: "date", order: "asc" },
        populate: "debitNoteAssignments.debitNote",
      });
      // console.log("getMatchingPayment", result);
      if (!result || !result.docs) return;

      setPayments(result.docs);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getMatchingTransaction();
    getMatchingPayment();
  }, [bankAccount?.id]);

  const reMatch = async () => {
    try {
      await getMatchingTransaction();
      await getMatchingPayment();
    } catch (error) {
      console.log(error);
    }
  };

  const refresh = async () => {
    try {
      await getMatchingTransaction();
      await getMatchingPayment();
    } catch (error) {
      console.log(error);
    }
  };

  const handleDragEnd = (result) => {
    console.log("handleDragEnd", result);
    const { destination, source, draggableId } = result;
    if (!destination || !source || !draggableId) return;
    if (source.droppableId === destination.droppableId) return;
    if (destination.droppableId === "PaymentList") return;

    //Source from other matching
    const sourceMatching = matchings.find((m) => m.id === source.droppableId);
    if (sourceMatching) return;

    //Source from payment list
    const sourcePayment = payments.find((d) => d._id === draggableId);

    const destinationMatching = matchings.find(
      (m) => m.id === destination.droppableId
    );

    // console.log(sourceMatching, destinationMatching, sourceDebitNote);

    if (!sourcePayment || !destinationMatching) return;

    const p = payments.find((d) => d._id === draggableId);
    if (!p) return;
    //Check if payment already in destination matching
    if (destinationMatching.payment) return;

    destinationMatching.payment = p;

    destinationMatching.totalReceivedAmount =
      destinationMatching.bankTransaction.amount;
    destinationMatching.totalPaymentAmount = destinationMatching.payment.amount;
    destinationMatching.totalDifference =
      destinationMatching.totalReceivedAmount -
      destinationMatching.totalPaymentAmount;

    p.matched = true;
    forceUpdate();
  };

  const onConfirm = async (transactionId) => {
    const newMatchings = matchings.filter(
      (m) => m.bankTransaction._id != transactionId
    );

    setMatchings(newMatchings ?? []);
    forceUpdate();
  };

  const getSkipMatching = (payments, matchings) => {
    const paymentMap = new Map();
    for (const payment of payments) {
      const key = `${moment(payment.date).format("YYYY-MM-DD")}/${
        payment.amount
      }`;
      if (paymentMap.has(key)) {
        paymentMap.set(key, paymentMap.get(key) + 1);
      } else {
        paymentMap.set(key, 1);
      }
    }

    const transactionMap = new Map();
    for (const matching of matchings) {
      const key = `${moment(matching.date).format("YYYY-MM-DD")}/${
        matching.amount
      }`;
      if (transactionMap.has(key)) {
        transactionMap.set(key, transactionMap.get(key) + 1);
      } else {
        transactionMap.set(key, 1);
      }
    }

    const skipMatching = [];
    //COMPARE the count of payment and transaction , if count is not same , the skip in matching
    for (const [key, count] of transactionMap) {
      const paymentCount = paymentMap.get(key) || 0;
      if (paymentCount !== count) {
        skipMatching.push({
          date: key.split("/")[0],
          amount: Number(key.split("/")[1]),
        });
        continue;
      }
    }
    return skipMatching;
  };

  const autoMatch = async () => {
    try {
      console.log("autoMatch", matchings, payments);

      //Get skip matching
      //for checking same date and amount while  transaction and payments count are not the same
      const skipMatching = getSkipMatching(payments, matchings);
      console.log("skipMatching", skipMatching);
      console.log("matchings", matchings.length);
      for (const matching of matchings) {
        for (const payment of payments) {
          if (payment.matched) continue;
          console.log(
            "payment",
            payment.amount,
            matching.totalDifference,
            moment(payment.date).isSame(matching.date, "day")
          );
          if (
            payment.amount === matching.totalDifference &&
            payment.amount > 0 &&
            moment(payment.date).isSame(matching.date, "day") &&
            //Is not in skipMatching
            !skipMatching.some(
              (s) =>
                moment(s.date).isSame(matching.date, "day") &&
                s.amount === matching.amount
            )
          ) {
            console.log(
              "auto match",
              payment.amount,
              matching.totalDifference,
              {
                destination: { droppableId: matching.id },
                source: { droppableId: payment._id },
                draggableId: payment._id,
              }
            );
            handleDragEnd({
              destination: { droppableId: matching.id },
              source: { droppableId: payment._id },
              draggableId: payment._id,
            });
          }
        }
      }
      forceUpdate();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Group position="right" spacing={5}>
        <Button
          size="xs"
          onClick={() => autoMatch()}
          color="green"
          disabled={matchings.length === 0 || payments.length === 0}
        >
          Auto Match
        </Button>
        <Button size="xs" onClick={() => refresh()} variant="default">
          Refresh
        </Button>

        <Menu shadow="md" width={200}>
          <Menu.Target>
            <ActionIcon>
              <IconDotsVertical size="1rem" />
            </ActionIcon>
          </Menu.Target>

          <Menu.Dropdown>
            <Menu.Item
              icon={<IconRefresh size={14} />}
              onClick={() => reMatch()}
            >
              Reset
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Group>

      <DragDropContext onDragEnd={handleDragEnd}>
        <Grid>
          <Grid.Col span={8}>
            <>
              <Group>
                <Text size="xs" weight="bold">
                  {t("Bank Transactions")}
                </Text>
                <Badge radius="sm" size="xs">
                  {matchings?.length ?? 0}
                </Badge>
              </Group>

              <ScrollArea style={{ height: "calc(100vh - 200px)" }}>
                {matchings &&
                  matchings?.map((matching) => (
                    <MatchCard
                      key={matching.id}
                      matching={matching}
                      onUndo={() => forceUpdate()}
                      bankAccount={bankAccount}
                      onConfirm={(transactionId) => onConfirm(transactionId)}
                    />
                  ))}
              </ScrollArea>
            </>
          </Grid.Col>
          <Grid.Col span={4}>
            <>
              <Group>
                <Text size="xs" weight="bold">
                  {t("Client Payments")}
                </Text>
                <Badge radius="sm" size="xs" color="orange">
                  {payments?.filter((d) => !d.matched)?.length}
                </Badge>
              </Group>

              <ScrollArea style={{ height: "calc(100vh - 200px)" }}>
                {payments && payments?.length > 0 && (
                  <Droppable droppableId={"PaymentList"} dropabled={false}>
                    {(provided, snapshot) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        {payments
                          ?.filter((d) => !d.matched)
                          ?.map((payment, index) => (
                            <PaymentCard
                              key={payment._id}
                              payment={payment}
                              index={index}
                            />
                          ))}
                      </div>
                    )}
                  </Droppable>
                )}
              </ScrollArea>
            </>
          </Grid.Col>
        </Grid>
      </DragDropContext>
    </>
  );
};

export default BankTransactionMatching;
