import React, { useState, useEffect } from "react";
import { ENUM_FORM_DISPLAY, ENUM_FORM_LAYOUT_CONTAINER } from "./pageList";
import { useFormRender } from "../hooks/useFormRender";
import { useTranslation } from "react-i18next";
import {
  Button,
  TextInput,
  Switch,
  Select,
  Textarea,
  ActionIcon,
  UnstyledButton,
  Group,
  Text,
  Autocomplete,
} from "@mantine/core";
import { DatePicker } from "@mantine/dates";

import {
  IconCircle,
  IconCirclePlus,
  IconExclamationMark,
  IconSettings,
} from "@tabler/icons";

import { useServerApi } from "../hooks/userServerApi";
import { showNotification } from "@mantine/notifications";
import _ from "lodash";
import RemoteSelect2 from "../components/remoteSelect2";
import { DATA_ORDER_TYPE, DATA_COUNTRY } from "../data/options";
import Chips from "./chips";

import FormConfirmation from "./formConfirmation";
import RemarkList from "./remarkList";
import ReactJson from "react-json-view";

const DATA_ENDORSEMENT_ACTION = [
  { label: "Debit Note", value: "DN" },
  { label: "Refund Money", value: "REFUND" },
  { label: "None", value: "NONE" },
  { label: "Credit Note", value: "CN" },
];

const DATA_ENDORSEMENT_TYPE = [
  { label: "Cover Period Change", value: "Cover Period Change" },
  { label: "Beneficiary Change", value: "Beneficiary Change" },
  { label: "Policy Term Change", value: "Policy Term Change" },
  { label: "Policy Fee Change", value: "Policy Fee Change" },
];

const DebitNoteAddEndorsementButton = ({ mainForm, onAdded }) => {
  const [api] = useServerApi();
  const dnId = mainForm?.values?._id;

  const apiEntity = "endorsement";
  const initialValues = {
    action: "NONE",
    date: new Date(),
  };

  const formSchema = {
    name: "AddEndorsementSchema",
    title: "New Endorsement",
    description: "Please fill in the form to create a New Endorsement",
    closeAfterSave: true,

    display: {
      mode: ENUM_FORM_DISPLAY.MODAL,
      size: "1000px", //xs, sm, xl  ....100%
      position: "right",
    },

    apiEntity,
    initialValues,

    validate: {
      action: (value) =>
        !value ? "Please select the endorsement action" : null,
      type: (value) => (!value ? "Please select the endorsement type" : null),
      error: (value) => {
        // console.log("error", value);
        return value;
      },
    },
    showSaveBar: true,

    layout: {
      containers: [
        {
          key: "t1",
          parent: null,
          type: ENUM_FORM_LAYOUT_CONTAINER.SECTION,

          props: {},
        },
      ],

      fields: [
        {
          name: "action",
          component: Select,
          parent: "t1",
          props: {
            label: "Action",
            required: true,

            data: DATA_ENDORSEMENT_ACTION,
            clearable: true,
          },
        },
        {
          name: "date",
          parent: "t1",
          component: DatePicker,
          props: {
            label: "Endorse Date",
            required: true,
            clearable: true,
            valueFormat: "YYYY-MM-DD",
            allowFreeInput: true,
          },
        },
        {
          name: "type",
          component: Autocomplete,
          parent: "t1",
          props: {
            label: "Endorsement Type",

            data: DATA_ENDORSEMENT_TYPE,
            clearable: true,
          },
        },
        {
          name: "remark",
          parent: "t1",
          component: Textarea,
          props: {
            label: "Remark",
          },
        },
      ],
    },
  };

  formSchema.title = "Add New Endorsement";

  const onSubmit = async ({ values, formMode }) => {
    try {
      //   console.log("DebitNoteAddEndorsementButton  > onSubmit", values);

      if (!dnId) throw `Debit Note Id not given`;
      const data = await api.DebitNote.addEndorsement({
        endorsement: values,
        dn: dnId,
      });

      //   console.log(data);
      formAction.close();
      if (onAdded) onAdded(data);

      showNotification({
        title: `Service added`,
        message: "Service added succesfully",
      });
    } catch (error) {
      console.log(error);
      showNotification({
        color: "red",
        icon: <IconExclamationMark size={18} />,
        title: `Service add error`,
        message: error.msg ?? error.message ?? error,
      });
    }
  };

  const [renderForm, formAction, formStatus, formSetting] = useFormRender(
    formSchema,
    null,
    onSubmit
  );

  const handleActionClick = () => {
    if (!mainForm || !mainForm.values) return;

    if (!dnId) return;

    const data = {
      originalDebitNote: dnId,
      date: new Date(),
      endorsedDebitNote: null,
      endorsedCreditNote: null,
      type: "",
      remark: "",
    };

    formAction.open({ mode: "edit", dnId, data });
  };

  const { t } = useTranslation();
  return (
    <>
      <UnstyledButton onClick={() => handleActionClick()}>
        <Group>
          <ActionIcon>
            <IconCirclePlus size={"1rem"} />
          </ActionIcon>
          <Text size={"xs"}>{t("Add New Endorsement")}</Text>
        </Group>
      </UnstyledButton>

      {renderForm()}
    </>
  );
};

export default DebitNoteAddEndorsementButton;
