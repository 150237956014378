import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  TextInput,
  Select,
  Textarea,
  NumberInput,
  Group,
  Title,
  Text,
  Divider,
  Button,
  Autocomplete,
} from "@mantine/core";
import ReactJson from "react-json-view";

import ArrayTable from "./arrayTable";
import { DATA_CURRENCY } from "../data/options";
import useDeepCompareEffect from "../hooks/useDeepCompareEffect";
import { useFormatter } from "../hooks/useFomatter";
import { useForceUpdate } from "@mantine/hooks";
import { useTranslation } from "react-i18next";
import { useServerApi } from "../hooks/userServerApi";
import RichTextEditor from "./richTextEditor";

const calculateTotal = (charges) => {
  if (_.isEmpty(charges)) return 0;
  const total = charges.reduce((a, c) => a + (c.qty ?? 1) * c.unitPrice, 0);
  return +total.toFixed(2);
};

const DebitNoteCostItems = ({ form, name, allowGetRate = false }) => {
  const costItems = _.get(form.values, "costItems");
  const chargeItems = _.get(form.values, "chargeItems");
  // const commissionRate = _.get(form.values, "commissionRate")
  const forceUpdate = useForceUpdate();

  const showFixRate = process.env.REACT_APP_DEBIT_NOTE_SHOW_FIX_RATE === "true";

  const [api] = useServerApi();

  const [total, setTotal] = useState(0);
  const [commissionRate, setCommissionRate] = useState(
    _.get(form.values, "commissionRate")
  );

  const [formatter] = useFormatter();
  const COMMON_CHARGE_ITEMS = ["Premium", "IA Levy", "EC Levy", "MIB Levy"];

  const rowInitValue = {
    item: "",
    currency: "HKD",
    qty: 1,
    unitPrice: 0,
    price: 0,
  };

  const fields = [
    {
      header: "Item",
      headerWidth: "50%",
      fieldName: "item",
      component: Autocomplete,
      props: {
        data: COMMON_CHARGE_ITEMS,
        searchable: true,
        clearable: true,
      },
    },

    {
      header: "Qty",
      headerWidth: "10%",
      fieldName: "qty",
      component: NumberInput,
      props: {},
    },
    {
      header: "Currency",
      headerWidth: "12%",
      fieldName: "currency",
      component: Select,
      props: {
        data: DATA_CURRENCY,
        defaultValue: "HKD",
        searchable: true,
      },
    },
    {
      header: "Unit Price",
      headerWidth: "30%",
      fieldName: "unitPrice",
      component: NumberInput,
      props: {
        parser: formatter.currencyParser,
        formatter: formatter.currencyFormat,
        precision: 2,
      },
    },
  ];

  const onChargeChange = (charges) => {
    const total = calculateTotal(charges);
    setTotal(total);
  };

  useEffect(() => {
    onChargeChange(costItems);
  }, [costItems]);

  useEffect(() => {
    form.setFieldValue("costTotal", total);
  }, [total]);

  //Just for GI , just get the first row.
  const getCommissionRate = async (id) => {
    if (!id) return;

    try {
      const table = await api.Product.getCurrentCommissionRateTable({
        id,
        date: form.values.date,
      });

      if (!table || !table.commissionVariation) return;

      const { rows } = table.commissionVariation;
      const row = rows[0];
      if (!row || !row.yr1) return;
      // console.log("getCommissionRate", rows, row.yr1);
      setCommissionRate(row.yr1);
      form.setFieldValue("commissionRate", row.yr1);

      forceUpdate();
      // console.log("getCommissionRate", row.yr1);
    } catch (error) {
      console.log(error);
    }
  };

  const applyCommissionRate = () => {
    let cr = _.get(form.values, "commissionRate");
    const chargeItems = _.get(form.values, "chargeItems");
    let newCostItems = _.cloneDeep(chargeItems);
    newCostItems = newCostItems.filter((item) => item.unitPrice >= 0);
    const pItem = newCostItems.find((c) => c.item === "Premium");

    if (pItem) {
      let unitPrice = pItem.unitPrice * (1 - cr / 100);
      pItem.price = unitPrice * pItem.qty;
      pItem.unitPrice = unitPrice;
      pItem.item = "Net Premium";
    }

    // console.log("commissionRate", commissionRate, pItem);

    form.setFieldValue("costItems", _.cloneDeep(newCostItems));
    form.setFieldValue("commissionRate", cr);
    // console.log("costItem", costItem, commissionRate);

    if (cr >= 0) {
      form.setFieldValue("appliedCommissionRate", true);
    }

    forceUpdate();
  };

  const applyFixRate = () => {
    // console.log("applyFixRate");
    const providerFixRate = _.get(form.values, "providerFixRate");
    const costItems = _.get(form.values, "costItems");
    const chargeItems = _.get(form.values, "chargeItems");
    const insuredValue = _.get(form.values, "insuredValue");
    let newCostItems = _.cloneDeep(chargeItems);
    newCostItems = newCostItems.filter((c) => c.item !== "Premium");

    //Add new Cost items to top
    const netPremiumValue = +(insuredValue * providerFixRate * 0.01).toFixed(2);
    newCostItems.unshift({
      item: "Net Premium",
      currency: "HKD",
      qty: 1,
      unitPrice: netPremiumValue,
      price: netPremiumValue,
    });

    form.setFieldValue("costItems", newCostItems);

    // form.setFieldValue("costItems", costItems);
    const total = calculateTotal(costItems);
    setTotal(total);
    forceUpdate();
  };

  const { t } = useTranslation();
  return (
    <>
      {/* {_.get(form.values, "insuredValue")} */}
      {/* <ReactJson src={form.values.product} style={{ background: "white" }} /> */}
      {/* {_.get(form.values, "commissionRate")} */}
      <Group position="apart">
        <Group align={"end"} mb="xl">
          <NumberInput
            precision={2}
            label="Commission Rate(%)"
            placeholder="30"
            value={_.get(form.values, "commissionRate")}
            onChange={(value) => {
              setCommissionRate(value);
              form.setFieldValue("commissionRate", value);
            }}
            size="xs"
          ></NumberInput>

          {allowGetRate && form.values.product && (
            <Button
              size="xs"
              onClick={() => getCommissionRate(form.values.product._id)}
            >
              {t("Get Rate")}
            </Button>
          )}
          <Button
            size="xs"
            color="green"
            onClick={() => applyCommissionRate()}
            // disabled={form.values.commissionRate <= 0}
          >
            {t("Apply Rate")}
          </Button>
        </Group>
        {showFixRate && (
          <Group align={"end"} mb="xl">
            <NumberInput
              precision={2}
              label="Provider Fix Rate(%)"
              placeholder="30"
              value={_.get(form.values, "providerFixRate")}
              onChange={(value) => form.setFieldValue("providerFixRate", value)}
              size="xs"
              formatter={formatter.numberFormat}
              parser={formatter.numberParser}
            ></NumberInput>
            <Button size="xs" color="green" onClick={() => applyFixRate()}>
              Apply Fix Rate
            </Button>
          </Group>
        )}
      </Group>
      <ArrayTable
        form={form}
        name={name}
        rowInitValue={rowInitValue}
        fields={fields}
        onDataChange={onChargeChange}
      />
      <Divider my="xl" />
      <Group position="right">
        <Group>
          <Title order={3}>{formatter.currency(total)}</Title>
        </Group>
      </Group>
      {/* <RichTextEditor
        form={form}
        name="providerCostReference"
        label="Please provide commission screenshot from provider"
        mt={"xl"}
      /> */}
    </>
  );
};

export default DebitNoteCostItems;
