import React, { useEffect, useState } from "react";
import { useServerApi } from "../hooks/userServerApi";
import _ from "lodash";
import moment from "moment";
import {
  PageList,
  ENUM_FORM_DISPLAY,
  ENUM_FORM_LAYOUT_CONTAINER,
} from "./pageList";
import { Text, Tabs, Badge } from "@mantine/core";

import ReactJson from "react-json-view";
import { useCellRender } from "../hooks/useCellRender";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuthUser } from "react-auth-kit";

const ClientDebitNoteHistory = ({ form, ...props }) => {
  const clientId = _.get(form.values, "_id");
  const [api] = useServerApi();
  const [cellRender] = useCellRender();
  const location = useLocation();
  const [orderLinkPath, setOrderLinkPath] = useState("consultant");
  const [debitNoteLinkPath, setDebitNoteLinkPath] = useState(null);

  const auth = useAuthUser();

  const userRoleName = auth().userRole?.name?.toUpperCase() ?? "";
  const showCostItems =
    _.isEmpty(process.env.REACT_APP_DEBIT_NOTE_SHOW_COST_ITEMS_USER_ROLE) ||
    process.env.REACT_APP_DEBIT_NOTE_SHOW_COST_ITEMS_USER_ROLE?.split(
      ","
    ).includes(userRoleName);

  useEffect(() => {
    if (
      userRoleName === "GI OPERATION" ||
      userRoleName === "OPERATION" ||
      userRoleName === "MANAGEMENT"
    )
      setOrderLinkPath("operation");
    if (
      userRoleName === "GI OPERATION" ||
      userRoleName === "OPERATION" ||
      userRoleName === "MANAGEMENT"
    )
      setDebitNoteLinkPath(
        auth()?.userRole?.entityLinks?.find((l) => l.entity === "debitNote")
          ?.link ?? "/m/gi/debitNote"
      );
  }, [userRoleName]);

  const orderFormSchema = {
    display: {
      mode: ENUM_FORM_DISPLAY.FULLSCREEN,
      // size: "xl",     //xs, sm, xl  ....100%
      // position:"right"
    },
  };

  const tableSchema = {
    preQuery: { client: clientId },
    searchableFields: ["code", "product.name", "status"],
    searchableFields: ["code", "product.name", "status"],

    columns: [
      {
        field: "signedDate",
        filter: true,
        headerName: "Sign Date",
        cellRender: (col, order) =>
          order.signedDate
            ? moment(order.signedDate).format("YYYY-MM-DD")
            : "-",
      },
      {
        field: "code",
        filter: true,
        cellRender: (col, order) => (
          <div>
            <a
              href={`/${orderLinkPath}/order?id=${order._id}&mode=${"edit"}`}
              target="_blank"
              style={{ textDecoration: "none" }}
            >
              {order?.code}
            </a>
            <Text color="dimmed"> {order?.policyNumber} </Text>
          </div>
        ),
      },
      {
        field: "product.type.name",
        filter: true,
        headerName: "Type",

        cellRender: (col, order) => (
          <>
            <Text c={"dimmed"}>{order.product?.type?.name}</Text>
            {order.product?.type?.name}
          </>
        ),
      },
      {
        field: "product.name",
        filter: true,
        headerName: "Product",

        cellRender: (col, order) => (
          <>
            <Text c={"dimmed"}>{order?.product?.name}</Text>
            {order.product?.name}
          </>
        ),
      },
      { field: "payment.payMode", filter: true, headerName: "Pay Mode" },
      {
        field: "payment.premium",
        filter: true,
        headerName: "Premium",
        cellRender: (col, order) =>
          cellRender.Currency(order.payment?.premium, order.payment?.currency),
      },

      // { field: 'client',          filter: true },

      {
        field: "status",
        filter: true,
        cellRender: (col, order) => cellRender.Order.status(order.status),
      },
    ],
  };

  const debitNoteTableSchema = {
    preQuery: { client: clientId },

    showActionColumn: true,
    copyToNewFields: [
      "type",
      "client",
      "commissionRate",
      "product",
      "discountAmount",
      "clientPayable",
      "period",
      "consultants",
      "signedBy",
      "checkList",
      "fileList",
      "to",
      "address",
      "addressStr",
      "descriptions",
      "terms",
      "chargeItems",
      "costItems",
      "refundItems",
      "product",
      "order",
      "total",
      "costTotal",
      "profit",
      "currency",
      "files",
      "insured",
    ],
    columns: [
      {
        field: "code",
        headerName: "DN",
        sortable: true,
        cellRender: (col, data) =>
          cellRender.DebitNoteCode(data, true, true, debitNoteLinkPath),
      },
      {
        field: "paymentStatus",
        headerName: "Payment",
        sortable: true,

        cellRender: (col, data) => (
          <>
            {cellRender.DebitNotePaymentStatus(data.paymentStatus)}
            <div>{cellRender.Currency(data?.clientPayable ?? 0)}</div>

            <Text
              color={
                !data?.payment?.amount
                  ? "red"
                  : data?.payment?.amount < data.clientPayable
                  ? "red"
                  : "green"
              }
            >
              {cellRender.Currency(data?.payment?.amount ?? 0)}
            </Text>
            {data.discountAmount > 0 && (
              <span>
                <Badge size="xs" radius={0} color="red" mt="xs" mb={1}>
                  Discount
                </Badge>
                {cellRender.Currency(-data.discountAmount)}
              </span>
            )}
          </>
        ),
      },

      {
        field: "product",
        headerName: "Product",
        sortable: true,
        cellRender: (col, data) => cellRender.ProductInfo(data.product),
      },
      // {
      //   field: "policyNumber",
      //   headerName: "Policy Number",
      //   sortable: true,
      // },
      {
        field: "policyNumber",
        headerName: "Policy No.",
        sortable: true,
        cellRender: (col, data) => (
          <div>
            <Text>{data.policyNumber ?? ""}</Text>
            {data.period?.start && data.period?.end && (
              <Text size="xs" color="dimmed">
                {moment(data.period.start).format("YYYY/MM/DD")} -{" "}
                {moment(data.period.end).format("YYYY/MM/DD")}
              </Text>
            )}
          </div>
        ),
      },
    ],
    searchableFields: [
      "status",
      "clientType._id",
      "productType._id",
      "client.name",
      "searchText",
      "policyNumber",
    ],
    select: [
      "code clientType productType type period product policyNumber client consultants status payment paymentStatus clientPayable createdAt signedAt discountAmount commissionRate costTotal",
    ],
  };

  return (
    <>
      {/* <ReactJson src={auth()} /> */}
      {/* {clientId} */}
      {/* {userRole} */}
      {!clientId && <Text size="xs">No Client Id</Text>}
      {clientId && debitNoteLinkPath && (
        <PageList
          title=""
          apiEntity="debitNote"
          // initSort={{ by: "signedDate", order: "desc" }}
          hideSearchBar={true}
          tableSchema={debitNoteTableSchema}
          formSchema={orderFormSchema}
          hideActionButton={true}
          tablePadding={-10}
        />
      )}
    </>
  );
};

export default ClientDebitNoteHistory;
