import React, { useEffect, useState } from "react";
import { useServerApi } from "../hooks/userServerApi";
import _ from "lodash";
import moment from "moment";
import {
  PageList,
  ENUM_FORM_DISPLAY,
  ENUM_FORM_LAYOUT_CONTAINER,
} from "./pageList";
import { Text, Tabs, Badge } from "@mantine/core";

import ReactJson from "react-json-view";
import { useCellRender } from "../hooks/useCellRender";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuthUser } from "react-auth-kit";

const ClientOrderHistory = ({ form, ...props }) => {
  const clientId = _.get(form.values, "_id");
  const [api] = useServerApi();
  const [cellRender] = useCellRender();
  const location = useLocation();
  const [orderLinkPath, setOrderLinkPath] = useState("consultant");

  const auth = useAuthUser();

  const userRoleName = auth().userRole?.name?.toUpperCase() ?? "";

  useEffect(() => {
    if (
      userRoleName === "GI OPERATION" ||
      userRoleName === "OPERATION" ||
      userRoleName === "MANAGEMENT"
    )
      setOrderLinkPath("operation");
  }, [userRoleName]);

  const orderFormSchema = {
    display: {
      mode: ENUM_FORM_DISPLAY.FULLSCREEN,
      // size: "xl",     //xs, sm, xl  ....100%
      // position:"right"
    },
  };

  const tableSchema = {
    preQuery: { client: clientId },
    searchableFields: ["code", "product.name", "status"],
    searchableFields: ["code", "product.name", "status"],

    columns: [
      {
        field: "signedDate",
        filter: true,
        headerName: "Sign Date",
        cellRender: (col, order) =>
          order.signedDate
            ? moment(order.signedDate).format("YYYY-MM-DD")
            : "-",
      },
      {
        field: "code",
        filter: true,
        cellRender: (col, order) => (
          <div>
            <a
              href={`/${orderLinkPath}/order?id=${order._id}&mode=${"edit"}`}
              target="_blank"
              style={{ textDecoration: "none" }}
            >
              {order?.code}
            </a>
            <Text color="dimmed"> {order?.policyNumber} </Text>
          </div>
        ),
      },
      {
        field: "product.type.name",
        filter: true,
        headerName: "Type",

        cellRender: (col, order) => (
          <>
            <Text c={"dimmed"}>{order.product?.type?.name}</Text>
            {order.product?.type?.cname}
          </>
        ),
      },
      {
        field: "product.name",
        filter: true,
        headerName: "Product",

        cellRender: (col, order) => (
          <>
            <Text c={"dimmed"}>{order.product?.name}</Text>
            {order.product?.cname}
          </>
        ),
      },
      { field: "payment.payMode", filter: true, headerName: "Pay Mode" },
      {
        field: "payment.premium",
        filter: true,
        headerName: "Premium",
        cellRender: (col, order) =>
          cellRender.Currency(order.payment?.premium, order.payment?.currency),
      },

      // { field: 'client',          filter: true },

      {
        field: "status",
        filter: true,
        cellRender: (col, order) => cellRender.Order.status(order.status),
      },
    ],
  };

  return (
    <>
      {/* <ReactJson src={auth()} /> */}
      {/* {clientId} */}
      {/* {userRole} */}
      {!clientId && <Text size="xs">No Client Id</Text>}
      {clientId && (
        <PageList
          title=""
          apiEntity="order"
          initSort={{ by: "signedDate", order: "desc" }}
          hideSearchBar={true}
          tableSchema={tableSchema}
          formSchema={orderFormSchema}
          hideActionButton={true}
          tablePadding={-10}
        />
      )}
    </>
  );
};

export default ClientOrderHistory;
