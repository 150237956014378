import React, { useState, useEffect } from "react";
import { Text, Group, ActionIcon, UnstyledButton } from "@mantine/core";
import DataTable from "./dataTable";
import { useTranslation } from "react-i18next";
import { useServerApi } from "../hooks/userServerApi";
import _ from "lodash";
import DebitNoteAddEndorsementButton from "./debitNoteAddEndorsementButton";
import ActionCellRender from "./actionCellRender";
import { IconRefresh } from "@tabler/icons";
import moment from "moment";
import { useAuthUser } from "react-auth-kit";
import { useCellRender } from "../hooks/useCellRender";

const DebitNoteEndorsementList = ({ form: mainForm, name }) => {
  const dnId = mainForm?.values?._id;
  const [rows, setRows] = useState([]);
  const [printing, setPrinting] = useState(false);
  const [api] = useServerApi();
  const { t } = useTranslation();
  const auth = useAuthUser();
  const [cellRender] = useCellRender();
  const userRole = auth().userRole;

  const debitNoteLink =
    userRole.entityLinks.find((l) => l.entity === "debitNote")?.link ??
    "/m/gi/debitNote";

  const creditNoteLink =
    userRole.entityLinks.find((l) => l.entity === "creditNote")?.link ??
    "/m/gi/creditNote";

  const fetchEndorsements = async () => {
    try {
      if (!dnId) return;
      const data = await api.DebitNote.getEndorsements({
        id: dnId,
      });
      if (!data || !data.endorsements) return;
      // console.log(data);
      setRows(data.endorsements);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchEndorsements();
  }, [dnId]);

  const onAdded = () => {
    fetchEndorsements();
  };

  const deleteEndorsement = async (data) => {
    if (!window.confirm("Are you sure you want to delete this endorsement?"))
      return;

    const result = await api.DebitNote.deleteEndorsement({
      id: data._id,
    });
    // console.log("result", result);
    fetchEndorsements();
  };

  const handleActionBtnClick = ({ action, data, rowIndex }) => {
    // console.log("handleActionBtnClick", action, data, rowIndex);

    switch (action) {
      case "delete":
        return deleteEndorsement(data);
    }
  };

  const tableSchema = {
    hidePagination: true,
    hideSearchBar: true,
    columns: [
      {
        field: "date",
        headerName: "Date",
        cellRender: (_, data) =>
          data.date ? moment(data.date).format("YYYY-MM-DD") : "",
      },

      { field: "code", headerName: "Endorsement Code" },
      {
        field: "action",
        headerName: "Action",
        cellRender: (_, data) => cellRender.EndorsementAction(data),
      },

      {
        field: "document",
        headerName: "Document",
        cellRender: (_, data) => (
          <>
            {data.endorsedDebitNote ? (
              <a
                href={`${debitNoteLink}?id=${data.endorsedDebitNote._id}`}
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                {cellRender.DebitNoteCode(data.endorsedDebitNote, false)}
              </a>
            ) : (
              ""
            )}
            {data.endorsedCreditNote ? (
              <a
                href={`${creditNoteLink}?id=${data.endorsedCreditNote._id}`}
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                {cellRender.CreditNote.code(data.endorsedCreditNote, false)}
              </a>
            ) : (
              ""
            )}
          </>
        ),
      },
      {
        field: "action",
        cellRender: ActionCellRender,
        isAction: true,
        actions: [],
        actionMenu: [
          {
            name: "delete",
            label: "Delete",
            props: {
              color: "red",
              size: "xs",
            },
          },
        ],
      },
    ],
  };

  return (
    <>
      <Group position="right" mb="xs">
        <UnstyledButton onClick={() => fetchEndorsements()}>
          <Group spacing={2}>
            <ActionIcon size="sm">
              <IconRefresh size={16} />
            </ActionIcon>
            <Text size={"xs"}>{t("Refresh")}</Text>
          </Group>
        </UnstyledButton>
      </Group>
      {_.isEmpty(rows) && (
        <Group mb="xl">
          <Text size="xs">{t("No endorsements records found")}</Text>
        </Group>
      )}
      {!_.isEmpty(rows) && (
        <DataTable
          data={rows ?? []}
          columns={tableSchema.columns}
          hidePagination={true}
          hideSearchBar={true}
          // onRowDoubleClick={handleOnEdit}
          onActionBtnClick={handleActionBtnClick}
        />
      )}

      {!printing && (
        <DebitNoteAddEndorsementButton mainForm={mainForm} onAdded={onAdded} />
      )}
    </>
  );
};

export default DebitNoteEndorsementList;
