import { useEffect, useState, useRef } from "react";
import FileList from "./fileList";
import { Text } from "@mantine/core";
import _ from "lodash";
import { useServerApi } from "../hooks/userServerApi";
import { useTranslation } from "react-i18next";

const DNOrderFileList = ({ form, title, columnSpan = 4, disabled }) => {
  const [api] = useServerApi();
  const { t } = useTranslation();

  const o = _.get(form.values, "order");
  const orderId = o?._id;
  const NO_ORDER_MSG = "No Order Assigned yet.";
  const [order, setOrder] = useState(null);

  const fetchOrder = async () => {
    let data = await api.getById({ apiEntity: "order", id: orderId });
    if (data) setOrder(data);
  };

  useEffect(() => {
    fetchOrder();
  }, [orderId]);

  return (
    <>
      {/* {order && <Text>Order: {order?._id}</Text>} */}
      {order && (
        <FileList
          // form={form}
          // name={name}
          relatedObject={{
            entity: "order",
            id: orderId,
            object: order,
            fieldName: "files",
            onUpdate: (files) => {
              //   console.log("onUpdate files", files);
              setOrder({ ...order, files });
            },

            onRefresh: () => {
              fetchOrder();
            },
          }}
          staticFiles={order?.files}
          title={title}
          columnSpan={columnSpan}
          disabled={disabled}
        />
      )}
      {!orderId && <Text size="xs">{t(NO_ORDER_MSG)}</Text>}
    </>
  );
};

export default DNOrderFileList;
